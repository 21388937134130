import fetcher from '@/services/fetcher'
import type {
  AccountStatus,
  Benefit,
  ClaimedCode,
  InstantWinDraw,
  InstantWinDrawTickets,
  LoginResponse,
  Membership,
  Nullable,
  PasswordReset,
  Plan,
  TMembershipPeriod,
  TPlanTier,
  Ticket,
  User,
} from '@/types'
import { Countries, MembershipPeriods } from '@/utils/helper-objects'
import { isUser } from '@/utils/type-guard-utils'
import { toApiUrl, toPortalUrl } from '@/utils/url-generation-utils'
import { nextAvailablePlan } from '@/utils/utils'
import * as Sentry from '@sentry/vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import { capitalCase } from 'change-case'
import { DateTime, Interval } from 'luxon'
import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'

import { useFeatureFlagsStore } from './feature-flags'
import { usePlansStore } from './plans'

// https://runthatline.com/pinia-typescript-type-state-actions-getters/
type UserStoreState = {
  user: User | null
  claimedCodes: Array<ClaimedCode>
}

export const useUserStore = defineStore('user', {
  state: (): UserStoreState => ({
    user: null,
    claimedCodes: [],
  }),

  getters: {
    isLoggedIn({ user }): boolean {
      return isUser(user)
    },
    isLoggedOut(): boolean {
      return !this.isLoggedIn
    },
    // TODO This should be a new clone every time (in case its called multiple times)
    newEmptyUser(): Ref<User> {
      return ref({
        __type: 'User',
        city: '',
        country: Countries.UnitedKingdom,
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        phone: '',
        post_code: '',
        referral_link: '',
        referrals: [],
        referrer: false,
        region: '',
        status: '',
        address_line_1: '',
        address_line_2: '',
        subscriber_id: null,
        subscriptions: [],
        tickets: {
          draws: {},
          loyalty: {
            count: 0,
          },
          main: {
            count: 0,
          },
          welcome: {
            count: 0,
          },
        },
        consent: false,
        contact_optin: false,
        claimed_codes: [],
        credit: {
          totals: {
            overall: 0,
            cashable: 0,
            bonus: 0,
            instant_win_cashable: 0,
          },
        },
        loyalty: {
          total: {
            credits: 0,
            entries: 0,
          },
          subscriptions: {},
          extras: [],
        },
        tier: {
          in_trial: false,
          loyalty_credit: 0,
          loyalty_entries: 0,
          processing: false,
          period: MembershipPeriods.Monthly,
          plan_tier: 'standard',
        },
      })
    },
    tickets({ user }): Array<string> {
      return user?.tickets?.main?.numbers ?? []
    },
    loyaltyTickets({ user }): Array<string> {
      return user?.tickets?.loyalty?.numbers ?? []
    },
    welcomeTickets({ user }): Array<string> {
      return user?.tickets?.welcome?.numbers ?? []
    },
    drawTickets(): Nullable<Record<string, Ticket>> {
      return this.user?.tickets?.draws ?? null
    },
    instantWinTickets(): Array<Array<InstantWinDrawTickets>> {
      return Array.from(
        Object.values(this.user?.tickets?.instant_win?.draws ?? {})
      )
    },
    ticketCount({ user }): number {
      return (
        (user?.tickets?.main?.count ?? 0) + (user?.tickets?.loyalty?.count ?? 0)
      )
    },
    subscriptionTicketCount(): number {
      return (
        this.user?.subscriptions?.reduce(
          (acc: number, subscription: Membership) => {
            return acc + subscription.plan_tickets
          },
          0
        ) ?? 0
      )
    },
    instantWinTicketCount(): Array<{ drawName: string; count: number }> {
      return Object.entries(this.user?.tickets?.instant_win?.draws ?? {}).map(
        ([drawName, draw]: [string, Array<InstantWinDrawTickets>]) => {
          const count = draw.reduce(
            (drawAcc: number, { winning_entries, non_winning_entries }) => {
              return (
                drawAcc +
                (winning_entries?.length ?? 0) +
                (non_winning_entries?.length ?? 0)
              )
            },
            0
          )
          return { drawName: capitalCase(drawName), count }
        }
      )
    },
    instantWinCount(): number {
      return Object.values(this.user?.tickets?.instant_win?.draws ?? {}).reduce(
        (acc: number, draw: Array<InstantWinDrawTickets>) => {
          return (
            acc +
            draw.reduce(
              (acc: number, { winning_entries, non_winning_entries }) => {
                return acc + winning_entries.length + non_winning_entries.length
              },
              0
            )
          )
        },
        0
      )
    },
    isInTrial(): boolean {
      return this.user?.tier.in_trial ?? false
    },
    highestPlanTier(): Nullable<TPlanTier> {
      return this.user?.tier.plan_tier ?? null
    },
    highestPlanPeriod(): Nullable<TMembershipPeriod> {
      return this.user?.tier.period ?? null
    },
    highestPlan(): Nullable<Plan> {
      const plansStore = usePlansStore()

      // Find subscription matching both tier and period
      const matchingSubscription = this.user?.subscriptions?.find(
        (subscription) =>
          subscription.plan_tier === this.highestPlanTier &&
          subscription.plan_period === this.highestPlanPeriod
      )

      if (!matchingSubscription) {
        return null
      }

      // Find plan by subscription's code
      return (
        plansStore.allPlansArray.find(
          (plan) =>
            plan.code.toLowerCase() === matchingSubscription.code.toLowerCase()
        ) ?? null
      )
    },
    upsellToPlan(): Nullable<Plan> {
      const plansStore = usePlansStore()

      if (!this.highestPlanTier || !this.highestPlanPeriod) {
        return null
      }

      const nextAvailablePlanTiers = nextAvailablePlan(this.highestPlanTier)

      if (
        !nextAvailablePlanTiers ||
        this.highestPlanTier === nextAvailablePlanTiers
      ) {
        return null
      }

      // Try each next tier plan until we find one that exists
      return plansStore.getPlanByTier(
        nextAvailablePlanTiers,
        this.highestPlanPeriod
      )
    },
    showUpgradePlan(): boolean {
      return this.isLoggedIn && !!this.highestPlan && !!this.upsellToPlan
    },
    isImpersonating(): boolean {
      const cookies = useCookies(['impersonated'], {
        autoUpdateDependencies: true,
      })
      const impersonated = cookies.get('impersonated')

      return impersonated && this.isLoggedIn
    },
    hasSubscription(): boolean {
      return this.isLoggedIn && !!this.user?.subscriptions?.length
    },
  },

  actions: {
    async logIn(
      email: string,
      password: string,
      rememberMe: boolean = true,
      include: string[] = ['csi_with_resource'],
      bypassPasswordResetRequest: boolean = true
    ): Promise<User> {
      if (this.isLoggedIn) {
        return this.user as User
      }

      // TEMP FIX: Redirect until user has updated their password
      if (
        localStorage.getItem('has-reset-password') !== 'true' &&
        import.meta.env.GLP_APP_ENV === 'production' &&
        !bypassPasswordResetRequest
      ) {
        this.$router.push({ name: 'request-password-reset-immediate' })
        // @ts-ignore Will be removed when the above is fixed
        return true
      }

      const includeParams = include.map((i) => `includes[${i}]=1`).join('&')
      const { data, error } = await fetcher.post<LoginResponse>(
        toPortalUrl(`/login?${includeParams}`),
        {
          email,
          password,
        },
        true
      )

      if (error.value) {
        throw error.value
      }

      const loginResponse = data.value as LoginResponse
      const cookies = useCookies(['authToken'], {
        autoUpdateDependencies: true,
      })
      cookies.set('authToken', loginResponse.auth_token, {
        path: '/',
        expires: DateTime.now()
          .plus({ days: rememberMe ? 14 : 2 })
          .toJSDate(),
      })

      // Clear feature flags cache on login to ensure the user gets flags for their authenticated state
      const featureFlagsStore = useFeatureFlagsStore()
      featureFlagsStore.clearFeatureFlagsCache()

      // Immediately fetch new feature flags for authenticated user
      await featureFlagsStore.fetchFeatureFlags()

      const user = this.setUser(loginResponse.csi_content, password)

      return user as User
    },
    async logOut(): Promise<boolean> {
      const { error } = await fetcher.get(toPortalUrl('/logout'), true)

      if (error.value) {
        throw error.value
      }

      const cookies = useCookies(['authToken', 'impersonated'], {
        autoUpdateDependencies: true,
      })

      cookies.remove('authToken', { path: '/' })
      cookies.remove('impersonated', { path: '/' })

      // Clear feature flags cache on logout to ensure the user gets flags for their anonymous state
      const featureFlagsStore = useFeatureFlagsStore()
      featureFlagsStore.clearFeatureFlagsCache()

      // Immediately fetch new feature flags for anonymous user
      await featureFlagsStore.fetchFeatureFlags()

      this.user = null
      this.claimedCodes = []

      Sentry.setUser(null)

      return true
    },
    async logInOrCreate(
      userData: Partial<User> & { email: string; password: string }
    ): Promise<User> {
      if (this.isLoggedIn) {
        return this.user as User
      }

      const { error } = await fetcher.post<{
        id: string
        auth_token: string
      }>(toPortalUrl('/loginOrCreate?includes[csi]=1'), userData, true)

      if (error.value || !userData) {
        throw error.value ?? Error('An error occurred logging in or creating.')
      }

      localStorage.setItem('has-reset-password', 'true')

      let user = await this.logIn(
        userData.email,
        userData.password,
        true,
        ['csi'],
        true
      )

      user = {
        ...userData,
        ...user,
      }

      Sentry.setUser({
        ...user,
        password: null,
      })

      this.user = user

      this.setClaimedCodes(user.claimed_codes ?? [])

      return user
    },
    async setPasswordWithToken(userData: {
      email: string
      password: string
      password_confirmation: string
      token: string
      rememberMe: boolean
    }): Promise<User> {
      if (this.isLoggedIn) {
        return this.user as User
      }

      const { error } = await fetcher.post(
        toPortalUrl('/setPasswordWithToken'),
        userData,
        true
      )

      if (error.value || !userData) {
        throw error.value ?? Error('An error occurred creating user.')
      }

      localStorage.setItem('has-reset-password', 'true')

      let user = await this.logIn(
        userData.email,
        userData.password,
        userData.rememberMe,
        ['csi'],
        true
      )

      user = {
        ...userData,
        ...user,
      }

      Sentry.setUser({
        ...user,
        password: null,
      })

      this.user = user

      this.setClaimedCodes(user.claimed_codes ?? [])

      return user
    },
    async checkAccountStatus(email: string): Promise<AccountStatus> {
      const { data, error } = await fetcher.post<AccountStatus>(
        toPortalUrl('/checkAccountStatus'),
        { email }
      )

      if (error.value || !data.value?.status) {
        throw error.value ?? Error('Unable to check account status.')
      }

      return data.value
    },
    async sendPasswordResetRequest(email: string): Promise<boolean> {
      const { error } = await fetcher.post(
        toPortalUrl('/requestPasswordReset'),
        {
          email,
          site: 'new',
        },
        true
      )

      if (error.value) {
        throw error.value
      }

      return true
    },
    /**
     * Update the user's password and logs them in.
     * @param {PasswordReset} data
     * @returns  {boolean}
     */
    async resetPassword(data: PasswordReset): Promise<boolean> {
      const { error } = await fetcher.post(toPortalUrl('/resetPassword'), data)

      if (error.value) {
        throw error.value
      }

      localStorage.setItem('has-reset-password', 'true')

      await this.logOut()
      const user = await this.logIn(
        data.email,
        data.password,
        true,
        ['csi'],
        true
      )

      return isUser(user)
    },
    async fetchDetails(forceLoad: boolean = false): Promise<User | null> {
      if (this.user && !forceLoad) {
        return this.user
      }

      const { data, error } = await fetcher.get(
        toPortalUrl('/customers/me?includes[csi]=1'),
        true
      )

      if (error.value || !data.value) {
        throw (
          error.value ??
          Error(
            'A problem occurred fetching your details, please contact support.'
          )
        )
      }

      this.setUser(data.value.csi_content)

      Sentry.setUser({
        ...this.user,
        password: null,
      })

      return this.user
    },
    // TODO Fix return types from BE and setting here
    async fetchDetailsWithToken(
      email: string,
      key: string
    ): Promise<User & { [key: string]: any }> {
      const { data, error } = await fetcher.post<{ customer: User }>(
        toApiUrl('/payments/fetch'),
        {
          email,
          key,
        }
      )

      if (error.value) {
        throw error.value
      }

      return Object.assign(this.newEmptyUser.value, data.value?.customer ?? {})
    },
    setUser(details: LoginResponse['csi_content'], password?: string) {
      const periodMap: Record<string, TMembershipPeriod> = {
        month: 'monthly',
        year: 'yearly',
      } as const

      let updatedUser: User = {
        __type: 'User',
        city: details.city,
        country: details.country ?? Countries.UnitedKingdom,
        first_name: details.first_name,
        last_name: details.last_name,
        phone: details.phone,
        post_code: details.post_code ?? details.postal_code ?? '',
        referral_link: details.referral_link,
        referrals: [],
        referrer: false,
        region: details.region,
        status: details.status,
        address_line_1: details.address_line_1 ?? details.street1,
        address_line_2: details.address_line_2 ?? details.street2 ?? '',
        subscriber_id: details.subscriber_id,
        subscriptions: [],
        tickets: {
          loyalty: {
            count: 0,
          },
          main: {
            count: 0,
          },
          welcome: {
            count: 0,
          },
        },
        email: details.email,
        id: details?.id,
        password: password ?? '',
        consent: false,
        contact_optin: false,
        claimed_codes: details.claimed_codes ?? [],
        credit: Object.assign(
          {
            totals: {
              overall: 0,
              cashable: 0,
              bonus: 0,
            },
          },
          details.credit
        ),
        loyalty: details.loyalty,
        tier: details.tier,
        // dob?: DateTime | string, 2017-05-15 09:12:34
      }

      if (details.referrals) {
        updatedUser = {
          ...updatedUser,
          referrals: details.referrals ?? [],
          referrer: details.referrer,
        }
      }

      if (details.subscriptions) {
        updatedUser = {
          ...updatedUser,
          subscriptions: details.subscriptions.map((subscription) => ({
            ...subscription,
            plan_period: subscription.plan_period
              ? periodMap[subscription.plan_period as keyof typeof periodMap]
              : subscription.plan_period,
          })),
        }
      }

      if (details.tier) {
        updatedUser = {
          ...updatedUser,
          tier: {
            ...details.tier,
            period: details.tier.period
              ? periodMap[details.tier.period as keyof typeof periodMap]
              : details.tier.period,
          },
        }
      }

      if (details.tickets) {
        updatedUser = {
          ...updatedUser,
          tickets: details.tickets,
        }
      }

      Sentry.setUser({
        ...updatedUser,
        password: null,
      })

      if (!this.user) {
        this.user = {} as User
      }
      Object.assign(this.user, updatedUser)

      this.setClaimedCodes(updatedUser.claimed_codes ?? [])

      return updatedUser
    },
    async saveUser(userData: Partial<User> = {}) {
      const user = { ...this.user, ...userData }

      delete user.password

      const { error } = await fetcher.post(
        toPortalUrl(`customers/${user.id}`),
        user,
        true
      )

      if (error.value) {
        throw (
          error.value ??
          Error(
            'A problem occurred saving your details, please contact support.'
          )
        )
      }

      Sentry.setUser({
        ...this.user,
        password: null,
      })
    },

    async balanceWithdraw(value: number): Promise<boolean> {
      if (!this.user) {
        console.log('User must be logged in to withdraw.')
        return false
      }

      const { error } = await fetcher.post(
        toPortalUrl(`customers/${this.user.id}/cashout`),
        {
          value: value * 100,
        },
        true
      )

      if (error.value) {
        throw (
          error.value ??
          Error('A problem occurred withdrawing, please contact support.')
        )
      }

      // Minus the cashable balance from total
      this.user.credit.totals.cashable -= value * 100

      return true
    },

    /**
     * This will set (not update/insert!) the claimed codes for the user.
     *
     * @param {Array<ClaimedCode>} claimedCodes
     */
    setClaimedCodes(claimedCodes: Array<ClaimedCode>): Array<ClaimedCode> {
      this.claimedCodes = claimedCodes
        .map((code: ClaimedCode) => {
          if (!code.claimed_at?.isValid) {
            code.claimed_at = code.created_at
          }

          return code
        })
        .filter((code: ClaimedCode) =>
          Interval.fromDateTimes(
            code.claimed_at.minus({ minute: 1 }),
            code.claimed_at.plus({ days: 30 })
          ).contains(DateTime.now())
        )

      return this.claimedCodes
    },

    /**
     * Returns the claimed codes for a benefit within the last 30 days.
     * Or null if no claimed codes are found.
     *
     * @param {Benefit} benefit
     * @returns {Array<ClaimedCode>}
     */
    getClaimedCode(benefit: Benefit): Nullable<Array<ClaimedCode>> {
      const claimedCodes = this.claimedCodes.filter((c: ClaimedCode) => {
        return (
          c.type === benefit.redemption_category &&
          c.subtype === (benefit.redemption_subcategory ?? null) &&
          c.claimed_at.diffNow('days').days < 30
        )
      })

      return !claimedCodes?.length ? null : claimedCodes
    },

    /**
     * Updates the user tickets with recently purchased instant wins data.
     *
     * If no purchase ID exists. No update occurs.
     *
     * @param {User} userData
     * @param {InstantWinDraw} instantWinDraw
     * @param {InstantWinDrawTickets} instantWinDrawTickets
     * @returns
     */
    setInstantWinTickets(
      userData: User,
      instantWinDraw: InstantWinDraw,
      instantWinDrawTickets: InstantWinDrawTickets
    ) {
      if (!instantWinDrawTickets.purchase_id) {
        return
      }

      this.user = {
        ...userData,
        tickets: {
          ...(this.user?.tickets ?? {}),
          instant_win: {
            ...(this.user?.tickets?.instant_win ?? {}),
            totals: {
              active_entries:
                this.user?.tickets?.instant_win?.totals?.active_entries ?? 0,
              bonus_entries:
                this.user?.tickets?.instant_win?.totals?.bonus_entries ?? 0,
            },
            draws: {
              ...(this.user?.tickets?.instant_win?.draws ?? {}),
              [instantWinDraw.code]: [
                ...(this.user?.tickets?.instant_win?.draws?.[
                  instantWinDraw.code
                ] ?? []),
                {
                  draw_code: instantWinDraw.code,
                  purchased_at: DateTime.now(),
                  is_winner: instantWinDrawTickets.is_winner ?? false,
                  winning_entries: instantWinDrawTickets.winning_entries ?? [],
                  non_winning_entries:
                    instantWinDrawTickets.non_winning_entries ?? [],
                  purchase_id: instantWinDrawTickets.purchase_id,
                },
              ],
            },
          },
        },
      }
    },
    getLatestInstantWinTickets(
      instantWin: InstantWinDraw
    ): InstantWinDrawTickets {
      return [
        ...(this.user?.tickets?.instant_win?.draws?.[instantWin.code] ?? []),
      ].sort(
        (
          drawPurchase1: InstantWinDrawTickets,
          drawPurchase2: InstantWinDrawTickets
        ) =>
          drawPurchase2.purchased_at.diff(
            drawPurchase1.purchased_at,
            'milliseconds'
          ).milliseconds
      )[0]
    },

    getSubscriptionById(id: number): Nullable<Membership> {
      return (
        this.user?.subscriptions?.find((subscription: Membership) => {
          return subscription.id === id
        }) ?? null
      )
    },
  },
})
