<script lang="ts">
import InstantPrizeCardWide from '@/components/cards/InstantPrizeCardWide.vue'
import PrizeCardWide from '@/components/cards/PrizeCardWide.vue'
import { useTailwind } from '@/plugins/useTailwind'
import { useInstantWinsStore } from '@/stores/instant-wins'
import { usePrizesStore } from '@/stores/prizes'
import {
  type InstantWinMainPrize,
  type InstantWinProgress,
  type Prize,
} from '@/types'
import { PrizeCategories } from '@/utils/helper-objects'
import { isInstantWinMainPrize } from '@/utils/type-guard-utils'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { PrizeCardWide, InstantPrizeCardWide },
  setup() {
    const prizesStore = usePrizesStore()
    const instantWinsStore = useInstantWinsStore()

    const tailwindBreakpoints = useTailwind().breakpoints
    const sliderBreakpoints = {
      0: {
        slidesPerView: 1,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        spaceBetween: 16,
      },
      [tailwindBreakpoints.xs]: {
        slidesPerView: 1.5,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        spaceBetween: 16,
      },
      [tailwindBreakpoints.sm]: {
        slidesPerView: 2,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        spaceBetween: 16,
      },
      [tailwindBreakpoints.md]: {
        slidesPerView: 3,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        spaceBetween: 16,
      },
      [tailwindBreakpoints.lg]: {
        slidesPerView: 4,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        spaceBetween: 16,
      },
    }

    return {
      sliderBreakpoints,
      isInstantWinMainPrize,
      prizesStore,
      instantWinsStore,
    }
  },
  data() {
    return {
      featuredPrizes: [] as Prize[],
      instantWinPrizes: [] as InstantWinMainPrize[],
      instantWinsProgressLoading: true,
      instantWinProgress: [] as InstantWinProgress[] | undefined,
    }
  },
  computed: {
    comingSoonCards() {
      return this.instantWinPrizes.length >= 4
        ? 0
        : 4 - this.instantWinPrizes.length
    },
  },
  async mounted() {
    await this.instantWinsStore.fetchInstantWins()
    await this.prizesStore.fetchPrizes()

    const futurePrizes = this.prizesStore.futurePrizes
      .filter((p: Prize) => {
        return p.categories.includes(PrizeCategories.Featured)
      })
      .sort((a, b) => {
        const aTime = a.close_at?.toMillis() || a.drawn_at.toMillis()
        const bTime = b.close_at?.toMillis() || b.drawn_at.toMillis()
        return aTime - bTime
      })

    const futureInstantWinMainPrizes =
      this.instantWinsStore.futureInstantWinMainPrizes

    if (
      futureInstantWinMainPrizes.length &&
      !futureInstantWinMainPrizes[0].categories.includes(
        PrizeCategories.Featured
      )
    ) {
      futureInstantWinMainPrizes[0].categories.push(PrizeCategories.Featured)
    }
    futureInstantWinMainPrizes.sort((a, b) => {
      const aTime = a.close_at?.toMillis() || a.drawn_at.toMillis()
      const bTime = b.close_at?.toMillis() || b.drawn_at.toMillis()
      return aTime - bTime
    })

    this.featuredPrizes = futurePrizes
    this.instantWinPrizes = futureInstantWinMainPrizes

    await this.loadInstantWinsProgress()
  },
  methods: {
    async loadInstantWinsProgress() {
      this.instantWinsProgressLoading = true

      try {
        this.instantWinProgress = await this.instantWinsStore.fetchProgress()
        this.instantWinsProgressLoading = false
      } catch (error) {
        console.error('Error loading progress:', error)
      }
    },
  },
})
</script>

<template>
  <AppSection>
    <div class="flex flex-col">
      <div class="mb-4 flex items-center justify-between">
        <h3 class="text-2xl font-bold">Instant Wins</h3>
        <AppChip
          v-if="instantWinPrizes.length > 4"
          :route="{
            name: 'prizes',
          }"
          text="View all"
        />
      </div>

      <GlSlider
        slider-id="featured-instant-prizes-slider"
        :pagination="true"
        :navigation="instantWinPrizes.length > 4"
        :breakpoints="sliderBreakpoints"
        left-navigation-wrapper-class="pb-16"
        right-navigation-wrapper-class="pb-16"
      >
        <InstantPrizeCardWide
          v-for="prize in instantWinPrizes"
          :key="prize.code"
          :prize="prize"
          :progress-percentage="
            instantWinProgress?.find((e) => e.code === prize.code)?.progress
          "
          :progress-loading="instantWinsProgressLoading"
        />

        <div
          v-for="i in comingSoonCards"
          class="flex flex-col"
          :key="`coming-soon-${i}`"
        >
          <img src="@/assets/images/coming_soon_card.png" alt="Coming Soon" />
          <div class="mb-0.5 mt-2 flex h-5 w-full rounded-full bg-slate-200" />
          <div
            class="mb-2 mt-3 flex w-full items-center justify-between text-sm text-black"
          >
            <div class="text-base font-bold">Coming Soon</div>
          </div>
        </div>
      </GlSlider>

      <div class="mb-4 flex items-center justify-between">
        <h3 class="text-2xl font-bold">Featured prizes</h3>
        <AppChip
          :route="{
            name: 'prizes',
          }"
          text="View all"
        />
      </div>

      <GlSlider
        slider-id="featured-prizes-slider"
        :pagination="true"
        :navigation="true"
        :breakpoints="sliderBreakpoints"
      >
        <PrizeCardWide
          v-for="prize in featuredPrizes"
          :key="prize.code"
          :prize="prize"
        />
      </GlSlider>
    </div>
  </AppSection>
</template>
